
















































































































































import { Component, Mixins } from 'vue-property-decorator';
import AcceptableAlphabetMixin from '@/mixins/AcceptableAlphabetMixin';

interface RegisterFormData {
  username: string;
  password: string;
  accountNumber: string;
  bank: string;
  accountName: string;
  lineId?: string;
  knownUsFrom: string;
}

@Component
export default class RegisterForm extends Mixins(AcceptableAlphabetMixin) {
  public username: string = '';

  public password: string = '';

  public accountNumber: string = '';

  public bank: string = '';

  public accountName: string = '';

  public lineId: string = '';

  public knownUsFrom: string = '';

  public getFormData(): RegisterFormData {
    return {
      username: this.username,
      password: this.password,
      accountNumber: this.accountNumber,
      bank: this.bank,
      accountName: this.accountName,
      lineId: this.lineId,
      knownUsFrom: this.knownUsFrom,
    };
  }
}
