































































































import { Component, Vue } from 'vue-property-decorator';
import RegisterForm from '@/components/RegisterForm.vue';
import UserService from '@/services/UserService';

interface RegisterFormData {
  username: string;
  password: string;
  accountNumber: string;
  bank: string;
  accountName: string;
  lineId?: string;
  knownUsFrom: string;
}

@Component({
  components: {
    RegisterForm,
  },
})
export default class RegisterPage extends Vue {
  public loading: boolean = false;

  private userService = new UserService();

  public async registerUser() {
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      const data = (this.$refs.REGISTER_FORM as Vue & { getFormData: () => RegisterFormData }).getFormData();
      await this.userService.registerUser({
        username: data.username,
        password: data.password,
        accountNumber: data.accountNumber,
        bank: data.bank,
        accountName: data.accountName,
        lineId: data.lineId,
        knownUsFrom: data.knownUsFrom,
      });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'สมัครสามาชิกสำเร็จ', error: true });
      this.$router.push('/login');
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }
}
