var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","label":"ชื่อผู้ใช้ เป็นภาษาอังกฤษหรือตัวเลขเท่านั้น","filled":"","rounded":"","dense":"","hide-details":""},on:{"keypress":function($event){return _vm.isEnglishAlphabet($event)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","label":"รหัสผ่าน 8 ตัวขึ้นไป","type":"password","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-select',{attrs:{"dark":"","items":[
          { text: 'ธนาคารกสิกรไทย', value: 'kbank' },
          { text: 'ธนาคารไทยพานิชย์', value: 'scb' },
          { text: 'ธนาคารกรุงไทย', value: 'ktb' },
          { text: 'ธนาคารกรุงเทพ', value: 'bbl' },
          { text: 'ธนาคารธนชาต', value: 'tbank' },
          { text: 'ธนาคารทหารไทย', value: 'tmb' },
          { text: 'ธนาคาร UOB', value: 'uob' },
          { text: 'ธนาคารกรุงศรีอยุธยา', value : 'bay'},
          { text: 'ธนาคารออมสิน', value : 'gsb'},
          { text: 'ธนาคาร ธกส', value: 'bacc' } ],"label":"โปรดเลือกธนาคารของท่าน","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.bank),callback:function ($$v) {_vm.bank=$$v},expression:"bank"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","label":"เลขบัญชีธนาคาร","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","label":"ชื่อ-นามสกุลหน้าเล่ม","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-text-field',{attrs:{"dark":"","label":"ID LINE","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.lineId),callback:function ($$v) {_vm.lineId=$$v},expression:"lineId"}})],1)],1),_c('v-divider',{staticClass:"my-5 white"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
          { text: 'Facebook', value: 'facebook' },
          { text: 'Youtube', value: 'youtube' },
          { text: 'Search Engine', value: 'search_engine' },
          { text: 'SMS โฆษณา', value: 'sms' },
          { text: 'เพื่อนแนะนำ', value: 'friend' },
          { text: 'ตัวแทนของเรา', value: 'agent' } ],"label":"ท่านเราจักเราผ่านช่องทางใด","dark":"","filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.knownUsFrom),callback:function ($$v) {_vm.knownUsFrom=$$v},expression:"knownUsFrom"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }